<template>
  <div>
    <v-row justify="center">
      <v-col lg="8">
        <v-card class="card--shadow">
          <v-card-title>
            Presumptive
            <v-spacer />
            <v-btn
              @click="viewPresumptiveReferral"
              class="mt-2 mr-1"
              outlined
              color="teal accent-4"
              dark
            >
              <v-icon left dark>mdi-format-list-bulleted</v-icon>Presumptive
              Referral
            </v-btn>
            <v-btn
              @click="viewPresumptiveList"
              class="mt-2 mr-1"
              outlined
              color="teal accent-4"
              dark
            >
              <v-icon left dark>mdi-format-list-bulleted</v-icon>Presumptive
              List
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <label>Index Case Year</label>
                <v-text-field
                  v-model.trim="indexYear"
                  type="text"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <label>Index Case Code</label>
                <v-text-field
                  v-model.trim="indexCode"
                  type="text"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <div>
                  <label>Mono/Poly</label>
                  <v-select
                    v-model="monopoly"
                    :items="monopolyOpt"
                    dense
                    outlined
                  ></v-select>
                </div>
              </v-col>
              <v-col v-show="admin.township.initial == 2">
                <label>Initial</label>
                <v-select
                  v-model="initial"
                  :items="initialOpt"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col class="align-self-center">
                <v-btn
                  height="40"
                  color="purple accent-4"
                  @click.prevent="searchPsp"
                  outlined
                  rounded
                  dark
                >
                  <v-icon left>mdi-magnify</v-icon>Search
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div v-if="showList && !loading" class="mt-5">
          <v-card
            elevation="0"
            color="pink accent-1"
            class="pa-2 d-flex justify-center bg-pink"
            v-if="presumptiveData.length == 0"
          >
            <p class="noti-text white--text">
              <v-icon class="white--text mr-1"
                >mdi-clipboard-alert-outline</v-icon
              >Sorry, no match patients!
            </p>
          </v-card>
          <div v-else>
            <search-list />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ValidationMixin } from "./../../mixins/ValidationMixin";
import { required } from "vuelidate/lib/validators";
import SearchList from "@/components/presumptive/SearchList.vue";
import { mapState } from "vuex";

export default {
  mixins: [ValidationMixin],
  data: () => ({
    showList: false,
    initialOpt: ["M", ""],
    monopolyOpt: ["No","Yes","SG"],
    initial: null,
    indexYear: null,
    monopoly:null,
    indexCode: null,
  }),
  validations: {
    IndexYear: {
      required,
    },
    indexCode: {
      required,
    },
  },
  methods: {
    searchPsp() {
      let data = {
        index_case_no: this.indexCode,
        index_case_year: this.indexYear,
        index_case_monopoly:this.monopoly=="Yes" ? 2 : 1,
        index_case_initial: this.initial ? this.initial : "",
      };

      this.showList = false;
      this.$store.dispatch("Presumptive/searchByIndexCode", data).then(() => {
        this.showList = true;
      });
    },

    viewPresumptiveList() {
      this.$router.push({
        name: "presumptive-list",
      });
    },
    viewPresumptiveReferral() {
      this.$router.push({
        name: "presumptive-referral",
      });
    },
  },
  components: {
    SearchList,
  },
  computed: {
    ...mapState("Presumptive", ["presumptiveData"]),
    ...mapState("Admin", ["admin"]),
    ...mapState("Loading", ["loading"]),
  },
};
</script>

<style scoped>
.noti-text {
  color: #ffffff;
  font-size: 18px;
  margin-top: 10px;
  margin-right: 10px;
  letter-spacing: 1px;
}
</style>
